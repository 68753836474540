import FormStepController from '../form_step_controller'

export default class extends FormStepController {

	static targets = [
		'absenceTypeIdInput',
		'progressIcon',
		'title',
		'editButton',
		'content',
	]

	connect() {
		super.connect()
		if (this.isStepComplete()) {
			this.completeStep()
		}
	}

	selectAbsenceType(event) {
		this.updateInputElement(event)
		this.highlightItem(event.currentTarget)
		this.updateSubmitStepInformation(event)
		this.completeStep()
	}

	updateInputElement(event) {
		const absenceTypeName = event.currentTarget.dataset.absenceTypeName
		const absenceTypeId = event.currentTarget.dataset.absenceTypeId
		this.absenceTypeIdInputTarget.value = absenceTypeId
		this.absenceTypeIdInputTarget.dataset.absenceTypeName = absenceTypeName
	}

	highlightItem(element) {
		if (element) {
			for (let userItem of this.element.querySelectorAll('#select-absence-type-list .absence-type-item.selected')) {
				userItem.classList.remove('selected')
			}
			element.classList.add('selected')
		}
	}

	updateSubmitStepInformation() {
		const submitStep = document.querySelector('#absence-form-submit-step')
		const absence_type_td = submitStep.querySelector('td.selected-absence-type')
		absence_type_td.innerHTML = this.absenceTypeIdInputTarget.dataset.absenceTypeName
	}

	highlightAbsenceTypeItem(element) {
		if (element) {
			for (let absenceTypeItem of this.element.querySelectorAll('#select-absence-type-list .absence-type-item.option-button.selected')) {
				absenceTypeItem.classList.remove('selected')
			}
			element.classList.add('selected')
		}
	}

	isStepComplete() {
		if (this.absenceTypeIdInputTarget.value) { return true }
		return false
	}

	startStep() {
		if (this.isStepComplete()) {
			this.showNextStep()
		} else {
			this.showStep()
		}
	}

	completeStep() {
		this.markComplete()
		this.updateTitle()
		this.showEditButton()
		this.hideStep()
		this.showNextStep()
	}

	updateTitle(){
		const absenceTypeName = this.absenceTypeIdInputTarget.dataset.absenceTypeName
		if (absenceTypeName) {
			const new_title = `<span class='d-none d-sm-inline'>Reason<i class='fa fal fa-angle-right mx-1'></i></span><span class='text-success'>${absenceTypeName}</span>`
			this.replaceTitle(new_title)
		} else {
			const new_title = `Select Reason for Absence`
			this.replaceTitle(new_title)
		}
	}

	showNextStep() {
		Promise.resolve().then( () => {
			document.querySelector('#absence-form-time-off-step').dispatchEvent(new CustomEvent('start-step'))
		})
	}

}
