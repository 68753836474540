import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect() {
		// TODO: if hidden? setTimeout(() => { this.showToast() }, 5)
		this.setToastExpiration()
	}	

	closeToast() {
		this.hideToast()
		setTimeout(() => { this.removeToastElement() }, 500 )
	}

	setToastExpiration() {
		setTimeout(() => { this.hideToast() }, 7000)
		setTimeout(() => { this.removeToastElement() }, 7500 )
	}

	showToast() {
		this.element.classList.add('show', 'animated', 'fadeInDown')
		this.element.classList.remove('hide')
	}

	hideToast() {
		if (this.element) {
			this.element.classList.remove('fadeInDown')
			this.element.classList.add('animated', 'fadeOutUp')
		}
	}

	removeToastElement() {
		if (this.element.parentNode) {
			this.element.parentNode.removeChild(this.element)
		}
	}
}
