import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = [
		'turboModal'
	]

	connect() {
		this.openModal()
	}	

	disconnect() {
		this.closeModal()
	}

	openModal() {
		const modal = this.getModalInstance()
		modal.show()
	}

	closeModal(event) {
		const modal = this.getModalInstance()
		modal.hide()
	}

	getModalInstance() {
		return bootstrap.Modal.getOrCreateInstance(this.turboModalTarget)
	}
}
