import ApplicationController from './application_controller'
import flatpicker from 'flatpickr'
//import dayjs from 'dayjs'

export default class extends ApplicationController {

	static values = {
		'flatpickrSelector': String,
	}

	static targets = [
		'showButton',
		'hideButton',
	]

	connect() {
		this.flatpickrElement = document.querySelector(this.flatpickrSelectorValue)
	}

	open() {
		this.flatpickrElement.dispatchEvent(new CustomEvent('open'))
		this.setButtonToHide()
	}

	close() {
		this.flatpickrElement.dispatchEvent(new CustomEvent('close'))
		this.setButtonToShow()
	}

	setButtonToHide() {
		this.showButtonTarget.classList.add('d-none')
		this.hideButtonTarget.classList.remove('d-none')
	}

	setButtonToShow() {
		console.log('setButtonToShow')
		this.showButtonTarget.classList.remove('d-none')
		this.hideButtonTarget.classList.add('d-none')
	}
}

