import $ from "jquery"
var async = require("async")

export default class Filer {
	constructor() {
		this.file_display_list = document.querySelector(".filer-list")
		this.file_display_count = document.querySelector(".filer-file-count")
		this.file_select_button = document.querySelector("button.filer-select-button")
		this.file_select_complete = document.querySelector(".filer-select-complete")
		this.file_upload_button = document.querySelector("button.filer-upload-button")
		this.file_upload_in_progress = document.querySelector(".filer-upload-in-progress")
		this.file_upload_complete = document.querySelector(".filer-upload-complete")
		this.file_import_pending = document.querySelector(".filer-import-pending")
		this.file_import_in_progress = document.querySelector(".filer-import-in-progress")
		this.file_import_complete = document.querySelector(".filer-import-complete")
		this.file_import_progress_card = document.querySelector(".filer-import-progress-card")
		this.file_import_progress = this.file_import_progress_card.querySelector(".filer-import-progress")
		this.file_input = document.querySelector("input.filer-input")
	}

	handle_files(event) {
		this.input_files = event.target.files
		if (!this.input_files || !this.input_files.length) {
			console.log('Filer: No files found')
			file_display_list.innerHTML = "<div class='no-files-message'><div class='fa fal fa-empty-set me-2'></div>No Files Selected.</div>"
		} else {
			console.log(`Filer: ${this.input_files.length} files found`)
			this.set_file_display_count()
			this.set_file_display_list()
			this.files_ready()
		}
	}

	set_file_display_count() {
		this.file_display_count.innerHTML = `${this.input_files.length} Files Selected`
	}

	set_file_display_list() {
		this.file_display_list.innerHTML = ''
		for (var file of this.input_files) {
			let printed_file_name = file.name.substring(0,50)
			if (file.name.length > 50) printed_file_name = printed_file_name + '...'

			let file_container = document.createElement('div')
			let file_class_name = this.get_file_css_class(file)
			file_container.classList.add('d-flex', 'flex-row', 'align-items-center', 'justify-content-between', 'w-100', file_class_name)

			let file_name_element = document.createElement('div')
			file_name_element.classList.add('filer-file-name', 'text-left')
			file_name_element.innerHTML = `<div class='fa fal fa-file-pdf me-2'></div>${printed_file_name}`

			let upload_indicator = document.createElement('div')
			upload_indicator.classList.add('filer-file-upload-indicator')
			upload_indicator.innerHTML = "Waiting for Upload to Start"
			file_container.appendChild(file_name_element)
			file_container.appendChild(upload_indicator)

			this.file_display_list.appendChild(file_container)

			let hr_element = document.createElement('hr')
			hr_element.classList.add('w-100', 'my-1')

			this.file_display_list.appendChild(hr_element)
		}
	}

	files_ready() {
		this.file_select_button.classList.remove('btn-theme', 'pulse')
		this.file_select_button.classList.add('btn-outline-secondary')
		this.file_select_button.innerHTML = "<div class='fa fal fa-folder-minus me-3'></div>Clear Selected Files"

		this.file_upload_button.classList.remove('btn-secondary', 'disabled')
		this.file_upload_button.classList.add('btn-theme', 'animated', 'pulse', 'repeat-3')
		this.file_upload_button.disabled = false
	}

	upload_files() {
		this.set_display_state_to_upload()
		this.create_import()
	}

	create_import() {
		console.log('filer: create_import')
		fetch(filer_create_import_path)
			.then(response => response.json())
			.then(data => { this.import = data; data })
			.then(data => {
				var queue = async.queue(this.upload_file, 3)
				queue.drain( () => { this.start_import() } )
				for (var file of this.input_files) { queue.push({ context: this, file: file}) }
				//for (var file of this.input_files) { this.upload_file(file) }
			})
	}

	upload_file(args, callback) {
		if(!args || !args.context || !args.file) callback()
		var context = args.context
		var file = args.file
		context.update_file_progress_indicator(context, file, 'in_progress')
		const formData = new FormData()
		const csrf_token = document.getElementsByName('csrf-token')[0].content;
		formData.append('import_id', context.import.id)
		formData.append('file', file)

		fetch(filer_upload_path, {
			method: 'POST',
			headers : { 'X-CSRF-Token': csrf_token },
			body: formData
		})
			.then(response => response.json())
			.then(result => {
				console.log('Success:', result)
				context.update_file_progress_indicator(context, file, 'success')
				callback()
			})
			.catch(error => {
				console.error('Error:', error)
				context.update_file_progress_indicator(context, file, 'error')
				callback()
			})

	}

	update_file_progress_indicator(context, file, status) {
		let file_class_name = context.get_file_css_class(file)
		let file_container_element = context.file_display_list.querySelector(`.${file_class_name}`)
		let file_upload_indicator = file_container_element.querySelector('.filer-file-upload-indicator')
		console.log(status)
		switch(status) {
			case "in_progress":
				file_upload_indicator.innerHTML = '<div class="fa fal fa-spinner-third fa-spin text-theme"></div>'
				break
			case "success":
				file_upload_indicator.innerHTML = '<div class="fa fal fa-check text-success animated backInRight"></div>'
				break
			case "error":
				file_upload_indicator.innerHTML = '<div class="fa fal fa-times text-danger animated bounceIn"></div>'
				break
			default:
				file_upload_indicator.innerHTML = '<div class="fa fal fa-layer-group text-secondary me-2"></div>In Queue'
		}
	}

	get_file_css_class(file) {
		return 'file_' + file.name.replace(/[\\*!@#$%^&*()+=<>?,'";:\/\[\]. ]/g, '_')
	}

	set_display_state_to_upload() {
		for (var element of [this.file_select_button, this.file_upload_button]) { this.hide_element(element) }
		for (var element of [this.file_select_complete, this.file_upload_in_progress]) { this.show_element(element) }

		this.display_files = this.file_display_list.querySelectorAll('.file')

		for (var display_file of this.display_files) {
			let upload_indicator_element = display_file.querySelector('.filer-file-upload-indicator')
			upload_indicator_element.innerHTML = "<div class='fa fal fa-layer-group me-2'></div>Queued"
		}
	}

	start_import() {
		console.log('filer: start_import')
		this.set_display_state_to_import()
		let import_start_path = filer_import_start_path.replace('%IMPORT_ID%', this.import.id)
		fetch(import_start_path)
			.then(response => response.json())
			.then(data => { this.import_start_status = data; data })
			.then(() => { this.start_import_progress_monitor() })
	}

	set_display_state_to_import() {
		for (var element of [this.file_upload_in_progress, this.file_import_pending, this.file_display_count]) { this.hide_element(element) }
		for (var element of [this.file_upload_complete, this.file_import_in_progress]) { this.show_element(element) }

		this.file_import_progress_card.classList.remove('d-none')
		this.file_import_progress_card.classList.add('animated', 'fadeIn')

		this.file_display_list.innerHTML = "<h5 class='text-success my-3'><div class='fa fal fa-check me-3'></div>Upload Complete</h5>"
	}

	start_import_progress_monitor() {
		this.import_progress_timer = window.setTimeout(this.check_import_progress.bind(this), 1000)
	}

	check_import_progress() {
		var handle_progress = (import_progress_data) => {
			if(import_progress_data.total_files == import_progress_data.imported_files + import_progress_data.errored_files) {
				window.clearTimeout(this.import_progress_timer)
				let import_complete_path = filer_import_complete_path.replace('%IMPORT_ID%', this.import.id)
				Turbo.visit(import_complete_path, { action: 'replace' })
			} else {
				this.update_import_progress(import_progress_data)
				this.import_progress_timer = window.setTimeout(this.check_import_progress.bind(this), 1000)
			}
		}
		
		let import_progress_path = filer_import_progress_path.replace('%IMPORT_ID%', this.import.id)
		fetch(import_progress_path)
			.then(response => response.json())
			.then(data => { this.import_progress = data; handle_progress(data) })
	}

	update_import_progress(import_progress_data) {
		let total_files = import_progress_data.total_files
		let imported_files = import_progress_data.imported_files || 0
		let errored_files = import_progress_data.errored_files || 0
		let imported_percentage = Math.trunc(((imported_files + errored_files) / (total_files || 1)) * 100.0)
		let html = `
			<div class='circle-progress-bar my-2' style='--p1:${imported_percentage}%'></div>
			<h3 class='text-theme my-2'>Imported ${imported_files} of ${total_files} files</h3>
		`
		if (errored_files && errored_files > 0) {
			html += `<div class='my-2'><small>${errored_files} files with errors</small></div>`
		}

		this.file_import_progress.innerHTML = html
	}

	hide_element(element) {
		element.classList.add('d-none')
		element.classList.remove('d-inline-block')
		if (element.tagName == 'BUTTON') element.disabled = true
	}

	show_element(element) {
		element.classList.remove('d-none')
		element.classList.add('d-inline-block')
		if (element.tagName == 'BUTTON') element.disabled = false
	}

}

$(document).on('turbo:load', function() {
	let file_select_button = document.querySelector("button.filer-select-button")
	let file_upload_button = document.querySelector("button.filer-upload-button")
	let file_input = document.querySelector("input.filer-input")
	if (file_select_button && file_upload_button && file_input) {
		window.filer = new Filer()

		file_select_button.addEventListener("click", function (e) {
			file_input.click()
			e.preventDefault() // prevent navigation to "#"
		}, false)

		file_upload_button.addEventListener("click", function (e) {
			filer.upload_files()
		}, false)

		file_input.addEventListener("change", function(event) { filer.handle_files(event) }, false)
	}
})
