import ApplicationController from './application_controller'
import bootstrap from "bootstrap/dist/js/bootstrap.js"
import { createPopper } from '@popperjs/core';

export default class extends ApplicationController {

	connect() {
		let tooltip = bootstrap.Tooltip.getOrCreateInstance(this.element)
		tooltip.enable()
	}	

	disconnect() {
		let tooltip = bootstrap.Tooltip.getOrCreateInstance(this.element)
		tooltip.disable()
	}

}
