export default class FormStream {
	refresh(element) {
		const form = element.closest('form')
		form.action = form.action + '/form_refresh'
		form.requestSubmit()

		const formOverlayContainer = element.closest('.form-overlay-container')
		this.addFormOverlay(formOverlayContainer)
	}

	addFormOverlay(container) {
		let formOverlayElement = document.createElement('div')
		formOverlayElement.classList.add('form-overlay')
		formOverlayElement.innerHTML = '<div class="form-overlay-content"><div class="fa-8x"><i class="fa fal fa-circle-notch fa-fw fa-spin text-light"></i></div></div>'
		if (container) {
			container.appendChild(formOverlayElement)
		}
	}
}

window.FormStream = new FormStream()
