import ApplicationController from './application_controller'

export default class extends ApplicationController {

	static values = {
		itemSelector: String,
	}

	static targets = [
		'searchInput',
		'listSearchItem',
	]

	searchList(event) {
		const elements = this.listSearchItemTargets
		const search_text = event.currentTarget.value ? event.currentTarget.value.toUpperCase() : null
		const selector = this.itemSelectorValue || '.item-name'

		if (!search_text) {
			this.displayAllElements(elements)
		} else {
			this.filterElementsBySearch(elements, search_text, selector)
		}
	}

	filterElementsBySearch(elements, search_text, selector) {
		for (let element of elements) {
			const item_name = element.querySelector(selector).innerText.toUpperCase()
			if (item_name.indexOf(search_text) == -1) {
				element.classList.add('d-none')
			} else {
				element.classList.remove('d-none')
			}
		}
	}

	displayAllElements(element_array) {
		for (let element of element_array) {
			element.classList.remove('d-none')
		}
	}
}
