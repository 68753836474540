import FormStepController from '../form_step_controller'
import flatpicker from 'flatpickr'
import dayjs from 'dayjs'

export default class extends FormStepController {

	static targets = [
		'absenceDateInput',
		'daysSelectedHint',
		'progressIcon',
		'title',
		'editButton',
		'content',
		'continueButton',
	]

	connect() {
		super.connect()
		this.initFlatpickr()

		if (this.isStepComplete()) {
			this.updateUI()
			this.completeStep()
		}

		if (!document.querySelector('#absence-form-user-step')) this.startStep()
	}

	initFlatpickr() {
		const mode = this.getCurrentDateType()
		const flatpickr_config = {
			dateFormat: 'Y-m-d',
			mode: mode,
			inline: true,
			//minDate: 'today',
		}

		this.calendar = flatpickr(this.absenceDateInputTarget, flatpickr_config)
	}

	getCurrentDateType() {
		const checkedDateTypeElement = this.element.querySelector("input[name='absence_dates_params[absence_date_type]']:checked")
		if (checkedDateTypeElement) return checkedDateTypeElement.value
		return 'single'
	}

	setDateMode(event) {
		this.absenceDateInputTarget.value = null
		const currentMonth = this.calendar.currentMonth
		this.calendar.clear()
		const dateMode = event.currentTarget.dataset.dateMode
		this.calendar.changeMonth(currentMonth, false)
		this.calendar.set('mode', dateMode)
		this.updateUI()
	}

	changeDate(event) {
		this.updateUI()
		if (this.isStepComplete()) {
			this.markComplete()
			this.enableContinueButton()
		} else {
			this.markIncomplete()
			this.disableContinueButton()
		}
	}

	updateUI() {
		this.updateDaysSelectedHint()
		this.updateTitle()
		this.updateSubmitStepInformation()
	}

	updateTitle() {
		if (this.isStepComplete()) {
			const new_title = `<span class='d-none d-sm-inline'>Date<i class='fa fal fa-angle-right mx-1'></i></span><span class='text-success'>${this.getSelectedDateStatus()}</span>`
			this.replaceTitle(new_title)
		} else {
			const new_title = 'Select Absence Date(s)'
			this.replaceTitle(new_title)
		}
	}

	updateDaysSelectedHint(){
		const selectedDateStatus = this.getSelectedDateStatus()
		this.daysSelectedHintTarget.innerHTML = selectedDateStatus
	}

	updateSubmitStepInformation() {
		const submitStep = document.querySelector('#absence-form-submit-step')
		const date_td = submitStep.querySelector('td.selected-dates')
		let date_td_content = '<div class="d-flex flex-column">'
		if (this.getCurrentDateType() == 'range') {
			const dateRange = this.getSelectedDateRange()
			if (dateRange.length != 2) { date_td_content += 'No Date Range Selected.' }
			else {
				date_td_content += `<div>From ${dateRange[0].format('MMMM D, YYYY (ddd)')}</div>`
				date_td_content += `<div>To ${dateRange[1].format('MMMM D, YYYY (ddd)')}</div>`
				date_td_content += `<hr class='my-1'><b>${this.getDateCount()} Total Days</b>`
			}
		}
		else {
			for (const date of this.getSortedDates()) {
				date_td_content += '<div>' + date.format('MMMM D, YYYY (ddd)') + '</div>'
			}
		}
		date_td_content += '</div>'
		date_td.innerHTML = date_td_content
	}

	getSelectedDates() {
		const dateInput = this.absenceDateInputTarget.value
		if (dateInput === "") { return [] }
		const dateStrings = dateInput.split(', ')
		const dates = []
		for (const dateString of dateStrings) {
			dates.push(dayjs(dateString, 'YYYY-MM-DD'))
		}
		return dates
	}

	getSortedDates() {
		const dates = this.getSelectedDates()
		return dates.sort((a,b) => { return a.valueOf() - b.valueOf() })
	}

	getSelectedDateRange() {
		const dateRangeInput = this.absenceDateInputTarget.value
		if (dateRangeInput === "") { return [] }

		const dateRangeStrings = dateRangeInput.split(' to ')
		if (dateRangeStrings.length != 2) return []

		const range = []
		range.push(dayjs(dateRangeStrings[0], 'YYYY-MM-DD'))
		range.push(dayjs(dateRangeStrings[1], 'YYYY-MM-DD'))

		return range
	}

	getDateCount() {
		if (this.getCurrentDateType() == 'range') {
			const dateRange = this.getSelectedDateRange()
			return dateRange[1].diff(dateRange[0], 'day') + 1
		} else {
			return this.getSelectedDates().length
		}
	}
	
	getSelectedDateStatus() {
		if (this.getCurrentDateType() == 'range') {
			const dates = this.getSelectedDateRange()
			if (dates.length == 0) { return 'No Days Selected' }
			else if (dates.length == 1) {
				return dates[0].format('MMMM D, YYYY (ddd)')
			}
			else { return `${this.getDateCount()} Days Selected` }
		} else {
			const dates = this.getSelectedDates()
			if (dates.length == 0) { return 'No Days Selected' }
			else if (dates.length == 1) {
				return dates[0].format('MMMM D, YYYY (ddd)')
			}
			else { return `${dates.length} Days Selected` }
		}
	}

	isStepComplete() {
		if (this.getCurrentDateType() == 'range') {
			if (this.getSelectedDateRange().length == 2) { return true }
		}
		else {
			if (this.absenceDateInputTarget.value.length >= 10) { return true }
		}
		return false
	}

	completeStep() {
		this.markComplete()
		this.showEditButton()
		this.hideStep()
		this.showNextStep()
	}

	startStep() {
		if (this.isStepComplete()) {
			this.showNextStep()
		} else {
			this.showStep()
		}
	}

	showNextStep() {
		Promise.resolve().then( () => {
			document.querySelector('#absence-form-absence-type-step').dispatchEvent(new CustomEvent('start-step'))
		})
	}

}

