// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import Rails from "@rails/ujs"
import "@hotwired/turbo"
//import * as ActiveStorage from "@rails/activestorage"
import "channels"

//Rails.start()
//ActiveStorage.start()

import "controllers"

import $ from "jquery"
window.$ = $
window.jQuery = window.$

import { createPopper } from '@popperjs/core';
window.createPopper = createPopper

// App-specific SCSS //
require("../scss/application.scss")

import bootstrap from "bootstrap/dist/js/bootstrap.js"
window.bootstrap = bootstrap

/* Not used yet
import "chartkick"
import "chart.js"
*/

/* only using dayjs inside of imported files
import dayjs from "dayjs"
window.dayjs = dayjs
*/ 

import flatpickr from "flatpickr"

import AOS from "aos/dist/aos.js"
window.AOS = AOS

import Sortable from 'sortablejs'
window.Sortable = Sortable

import Nav from '../app/nav.js'
window.nav = new Nav()

import { omnisearch_change, show_omnisearch_results } from '../app/omnisearch.js'
window.omnisearch_change = omnisearch_change
window.show_omnisearch_results = show_omnisearch_results

import TablesHelper from '../app/tables_helper.js'
window.tables = new TablesHelper()

require("../app/stimulus_reflex.js")
require("../app/toasts.js")
require("../app/dropdown_helper.js")
require("../app/disable_on_submit.js")
require("../app/tooltips.js")
require("../app/modals.js")

import Filer from '../app/filer.js'
import '../app/spin.js'
import '../app/form_stream.js'

import { filter_list, reset_filter_list } from '../app/filter_list.js'
window.filter_list = filter_list
window.reset_filter_list = reset_filter_list

import SharedResourcesEvents from '../app/shared_resources_events.js'
window.SharedResourcesEvents = SharedResourcesEvents
