import ApplicationController from '../application_controller'

export default class extends ApplicationController {

	static targets = [
		'inputComment',
		'submitButton',
		'disabledSubmitButton',
	]

	approvalActions = ['admin_approved', 'supervisor_approved']
	rejectionActions = ['admin_rejected', 'supervisor_rejected']

	getSelectedApprovalType() {
		const checkedApprovalType = this.element.querySelector("input[name='pto_absence_approval[approval_type]']:checked")
		if (checkedApprovalType) return checkedApprovalType.value;
		return null
	}

	formIsValid() {
		if (!this.getSelectedApprovalType()) return false;

		if (this.approvalActions.indexOf(this.getSelectedApprovalType()) >= 0) return true;

		if (this.rejectionActions.indexOf(this.getSelectedApprovalType()) >= 0 && this.inputCommentTarget.value) return true;

		return false
	}

	formUpdate(event) {
		if (this.formIsValid()) {
			this.enableSubmitButton()
			this.inputCommentTarget.classList.remove('border-danger')
		} else {
			this.disableSubmitButton() 
			if (!this.inputCommentTarget.value) {
				this.inputCommentTarget.classList.add('border-danger')
			}
		}

	}

	enableSubmitButton() {
		this.submitButtonTarget.classList.remove('d-none')
		this.disabledSubmitButtonTarget.classList.add('d-none')
	}

	disableSubmitButton() {
		this.submitButtonTarget.classList.add('d-none')
		this.disabledSubmitButtonTarget.classList.remove('d-none')
	}

}
