import { get_csrf_token } from 'app/csrf_token.js'

export default class SharedResourcesEvents {

	static item_add(event) {
		SharedResourcesEvents.save_item_changes(event)
		SharedResourcesEvents.save_list_order(event)
	}

	static item_update(event) {
		SharedResourcesEvents.save_list_order(event)
	}

	static card_update(event) {
		SharedResourcesEvents.save_category_order(event)
	}

	static save_item_changes(event) {
		const new_list = event.to
		const new_src_id = new_list.getAttribute('data-shared-resource-category-id')

		const item = event.item
		const item_url = item.getAttribute('data-url')

		fetch(item_url, {
			method: 'PATCH',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(SharedResourcesEvents.item_to_params(item, new_src_id))
		})
	}

	static item_to_params(item, shared_resource_category_id) {
		const item_uuid = item.getAttribute('data-uuid')
		const item_type = item.getAttribute('data-item-type')
		let params = {}
		params['authenticity_token'] = get_csrf_token()
		params[item_type] = {
			shared_resource_category_id: shared_resource_category_id
		}
		return params
	}

	static save_list_order(event) {
		const list = event.to
		const update_order_url = list.getAttribute('data-order-url')

		fetch(update_order_url, {
			method: 'PATCH',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(SharedResourcesEvents.get_list_order_params(list))
		})
	}

	static get_list_order_params(list) {
		const items = list.querySelectorAll('.shared-resource-item')

		let params = {}
		params['authenticity_token'] = get_csrf_token()
		params['items'] = []

		let index = 0
		for(let item of items) {
			let item_type = item.getAttribute('data-item-type')
			let item_uuid = item.getAttribute('data-uuid')
			params['items'].push([item_type, item_uuid, index])
			index += 1
		}

		return params
	}

	static save_category_order(event) {
		const category_list = event.to
		const update_category_order_url = category_list.getAttribute('data-category-order-url')

		fetch(update_category_order_url, {
			method: 'PATCH',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(SharedResourcesEvents.get_category_order_params(category_list))
		})
	}

	static get_category_order_params(category_list) {
		const categories = category_list.querySelectorAll('.shared-resource-category-card')

		let params = {}
		params['authenticity_token'] = get_csrf_token()
		params['categories'] = []

		let index = 0
		for(let category of categories) {
			let id = category.getAttribute('data-shared-resource-category-id')
			params['categories'].push([id, index])
			index += 1
		}

		return params
	}

}
