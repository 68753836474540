import ApplicationController from './application_controller'

export default class extends ApplicationController {

	static values = {
		containerSelector: String,
		tabSelector: String,
		buttonSelector: String,
	}

	connect() {
		this.containerSelectorValue = this.containerSelectorValue || '.tab-content'
		this.tabSelectorValue = this.tabSelectorValue || '.tab-pane'
		this.buttonSelectorValue = this.buttonSelectorValue || '.tab-option'
		this.container = document.querySelector(this.containerSelectorValue)
	}

	activateTab(event) {
		const tabButton = event.currentTarget
		const tabToActivate = tabButton.getAttribute('data-tab-target')
		if(!tabButton.classList.contains('disabled')) {
			this.inactivateAllButtons(this.element)
			this.activateButton(tabButton)
			this.hideAllTabs(this.container)
			this.showTab(this.container, tabToActivate)
		}
	}

	inactivateAllButtons(tabButtonGroup) {
		const buttons = tabButtonGroup.querySelectorAll(this.buttonSelectorValue)
		for(let button of buttons) {
			button.classList.remove('underline-animated')
			button.classList.remove('disabled')
		}
	}

	activateButton(button) {
		button.classList.add('underline-animated')
		button.classList.add('disabled')
	}

	// Timeouts are used below for handling bootstrap's fade animations
	hideAllTabs(tabContent) {
		const tabs = tabContent.querySelectorAll(this.tabSelectorValue)
		for(let tab of tabs) {
			tab.classList.remove('show')
			setTimeout( () => tab.classList.remove('active'), 150)
			setTimeout( () => tab.classList.add('d-none'), 150)
		}
	}

	showTab(tabContent, elementId) {
		const tab = tabContent.querySelector(elementId)
		setTimeout( () => tab.classList.remove('d-none'), 150)
		setTimeout( () => tab.classList.add('active'), 150)
		setTimeout( () => tab.classList.add('show'), 300)
	}

}
