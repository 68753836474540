import FormStepController from '../form_step_controller'

export default class extends FormStepController {

	static targets = [
		'content',
	]

	connect() {
		super.connect()
	}

	startStep() {
		this.showStep()
	}
}
