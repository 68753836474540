import ApplicationController from './application_controller'

export default class extends ApplicationController {

	static values = {
		disableDragScroll: String,
	}

	connect() {
		this.element.addEventListener('wheel', event => this.wheelHandler(this.element, event))

		if (this.disableDragScrollValue && this.disableDragScrollValue == 'true') return;

		this.element.addEventListener('mousedown', event => this.mouseDownHandler(this.element, event))
		this.element.addEventListener('mouseleave', event => this.mouseLeaveHandler())
		this.element.addEventListener('mouseup', event => this.mouseUpHandler())
		this.element.addEventListener('mousemove', event => this.mouseMoveHandler(this.element, event))
		this.isDown = false
		this.startX = 0
		this.scrollLeft = 0
	}

	wheelHandler(element, event) {
		const toLeft  = event.deltaY < 0 && element.scrollLeft > 0
		const toRight = event.deltaY > 0 && element.scrollLeft < element.scrollWidth - element.clientWidth

		if (toLeft || toRight) {
			event.preventDefault()
			event.stopPropagation()

			//element.scrollBy({ left: event.deltaY })
			element.scrollBy({ left: event.deltaY*3.5, behavior: 'smooth' })
		}
	}

	mouseDownHandler(element, event) {
		this.isDown = true
		this.startX = event.pageX - element.offsetLeft
		this.scrollLeft = element.scrollLeft
	}

	mouseLeaveHandler() {
		this.isDown = false
	}

	mouseUpHandler() {
		this.isDown = false
	}

	mouseMoveHandler(element, event) {
		if (!this.isDown) return;
		event.preventDefault()
		const x = event.pageX - element.offsetLeft
		const walk = x - this.startX
		element.scrollLeft = this.scrollLeft - walk
	}
}
