import ApplicationController from './application_controller'
import IMask from 'imask';

export default class extends ApplicationController {
	static values = {
		maskType:String,
	}

	connect() {
		this.maskType = this.maskTypeValue || 'phone'

		if(this.maskType === 'phone') {
			this.imask = IMask(this.element, { mask: '000{-}000{-}0000' });
		}

		if(this.maskType === 'pto_amount') {
			this.imask = IMask(this.element, { mask: Number, scale: 2, signed: false, normalizeZeros: true, radix: '.', max: 1000 });
		}

		if(this.maskType === 'date') {
			this.imask = IMask(this.element, { mask: Date, pattern: 'm/`d/`Y', format: this.format_date, parse: this.parse_date });
		}

	}	

	disconnect() {
		if (this.imask) { this.imask.destroy() }
	}

	format_date(date) {
		let day = date.getDate()
		let month = date.getMonth() + 1
		let year = date.getFullYear()

		if (day < 10) day = "0" + day
		if (month < 10) month = "0" + month

		return [month, day, year].join('/')
	}

	parse_date(date) {
		const monthDayYear = date.split('/');
		return new Date(monthDayYear[2], monthDayYear[0] - 1, monthDayYear[1]);
	}

	reset_value() {
		this.imask.updateValue('')
	}

}
