import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static values = {
		detailRowSelector: String
	}

	connect() {
		this.detailRow = document.querySelector(this.detailRowSelectorValue)
		this.parentTr = this.element.closest('tr')
	}	

	showDetailRow() {
		if (this.element.getAttribute('data-table-detail-row-action') === 'less') {
			this.detailRow.classList.add('d-none')
			this.updateBottomBorder('hide')
			this.switchButtonToMore()

		} else {
			this.detailRow.classList.remove('d-none')
			this.updateBottomBorder('show')
			this.switchButtonToLess()
		}
	}

	updateBottomBorder(action)
	{
		for(let element of this.parentTr.querySelectorAll('td')) {
			if (action === 'show') {
				element.classList.add('border-bottom-0')
			} else {
				element.classList.remove('border-bottom-0')
			}
		}
	}

	switchButtonToLess() {
		this.element.innerHTML = "<div class='fa fal fa-angle-double-down'></div>Less"
		this.element.setAttribute('data-table-detail-row-action', 'less')
	}

	switchButtonToMore() {
		this.element.innerHTML = "<div class='fa fal fa-angle-double-right'></div>More"
		this.element.setAttribute('data-table-detail-row-action', null)
	}

	hideDetailRow() {
		if (this.detailRow) {
			this.detailRow.classList.add('d-none')
		}
	}

	openAllDetailRows() {}
	closeAllDetailRows() {}

}
