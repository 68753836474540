import ApplicationController from '../application_controller'

export default class extends ApplicationController {
	static targets = [
		'categoryCard',
		'itemList',
	]

	connect() {
		this.initSortables()
	}

	initSortables() {
		new Sortable(
			this.itemListTarget,
			{
				group: 'shared_resources',
				handle: '.handle',
				animation: 150,
				ghostClass: 'sortable-ghost',
				forceFallback: true,
				onAdd: SharedResourcesEvents.item_add,
				onUpdate: SharedResourcesEvents.item_update,
			}
		)
	}
}
