import $ from "jquery"

export default class TablesHelper {

	row_click_redirect(event, url) {
		let sender_element = event.target
		if(sender_element) {
			const sender_tags = [sender_element.tagName.toUpperCase(), sender_element.parentElement.tagName.toUpperCase()]
			let allow_visit = true
			for(let tag of sender_tags) {
				if(tag && (tag === 'A' || tag === 'BUTTON')) { allow_visit = false }
			}
			if (allow_visit) { Turbo.visit(url) }
		}
		return true
	}

	show_turbo_modal(event, url) {
		let sender_element = event.target
		if(sender_element) {
			const sender_tags = [sender_element.tagName.toUpperCase(), sender_element.parentElement.tagName.toUpperCase()]
			let allow_visit = true
			for(let tag of sender_tags) {
				if(tag && (tag === 'A' || tag === 'BUTTON')) { allow_visit = false }
			}
			if (allow_visit) {
				const turboFrame = document.getElementById('turbo-modal')
				turboFrame.src = url
				turboFrame.reload()
			}
		}
	}
}

$(document).on('turbo:load', function() {
		$("th.sortable, .sortable .pagination").on("click", "a", function() {
				Turbo.visit(this.href)
				return false
			}
		)
		if ($("th.sortable i[name='sort-icon']").length === 0) {
			$("th.sortable a").append("&nbsp;<i name='sort-icon' class='fal fa-sort'></i>") 
		}
		$("th.sortable a.current [name='sort-icon']").remove()
		$("th.sortable a.current.asc").append("&nbsp;<i name='sort-icon' class='fal fa-sort-down'></i>")
		$("th.sortable a.current.desc").append("&nbsp;<i name='sort-icon' class='fal fa-sort-up'></i>")
	}
)

