import $ from 'jquery'

export function omnisearch_change(event) {
	clearTimeout(window.omnisearch_update_timer)
	window.omnisearch_update_timer = setTimeout(() => update_omnisearch() , 250)
}

function update_omnisearch() {
	var omnisearch_input = document.getElementById('omnisearch-input')
	var search_value = omnisearch_input.value
	var omnisearch_results = document.getElementById('omnisearch-results')

	$.get(`/search/${search_value}`, (data) => {
		var no_results_found_message = "<div class='dropdown-item'><i class='fal fa-empty-set fa-fw text-theme me-3'></i>No Results Found.</div>"

		if (data) {
			var results = JSON.parse(data)
			//console.log(data)

			if (results) {
				if (results['error']) { omnisearch_results.innerHTML = no_results_found_message }

				else {
					let results_string = ''

					if (results.length == 0) { results_string = no_results_found_message }

					else {
						for(var result of results) { results_string += render_omnisearch_result(result) }
					}

					if (results.length >= 10) {
						results_string += '<div class="small text-center my-1">Only showing first 10 results...</div>'
					}

					omnisearch_results.innerHTML = results_string
				}
			}
		}

		else { omnisearch_results.innerHTML = no_results_found_message }

		show_omnisearch_results()
	})
}

export function show_omnisearch_results() {
	var omnisearch_results = document.getElementById('omnisearch-results')
	omnisearch_results.style.display = 'block'
	window.omnisearch_popper.update()

	$(document).click( (event) => {
		if (!$(event.target).closest('#omnisearch-dropdown').length && !$(event.target).is('#omnisearch-results')) {
			document.getElementById('omnisearch-results').style.display = 'none'
		}
	})
}

function render_omnisearch_result(result_hash) {
	var id = result_hash['id']
	var name = result_hash['name']
	var type = result_hash['type']

	switch(type) {
		case 'user':
			return `<a class='dropdown-item' href='/users/${id}'>${name}<span class='text-theme ms-3' style='font-size: 0.8em;'><i class='fal fa-user fa-fw me-2'></i>Employee</a>`
		case 'paystub':
			return `<a class='dropdown-item' href='/paystubs/${id}'>${name}<span class='text-theme ms-3' style='font-size: 0.8em;'><i class='fal fa-money-check fa-fw me-2'></i>Paystub</a>`
	}
}
