import FormStepController from '../form_step_controller'

export default class extends FormStepController {

	static targets = [
		'userIdInput',
		'progressIcon',
		'title',
		'editButton',
		'content',
	]

	connect() {
		super.connect()
		if (!this.userIdInputTarget.value) this.contentCollapse.show()

		if (this.isStepComplete()) {
			this.updateSubmitStepInformation()
			this.completeStep()
		}
	}

	selectUser(event) {
		this.updateInputElement(event)
		this.highlightUserItem(event.currentTarget)
		this.updateSubmitStepInformation()
		this.completeStep()
	}

	updateInputElement(event) {
		const userName = event.currentTarget.dataset.userName
		const userId = event.currentTarget.dataset.userId
		this.userIdInputTarget.value = userId
		this.userIdInputTarget.dataset.userName = userName
	}

	highlightUserItem(element) {
		if (element) {
			for (let userItem of this.element.querySelectorAll('#select-user-list .user-item.selected')) {
				userItem.classList.remove('selected')
			}
			element.classList.add('selected')
		}
	}

	updateSubmitStepInformation() {
		const submitStep = document.querySelector('#absence-form-submit-step')
		const user_td = submitStep.querySelector('td.selected-user')
		user_td.innerHTML = this.userIdInputTarget.dataset.userName
	}

	isStepComplete() {
		if (this.userIdInputTarget.value) { return true }
		return false
	}

	completeStep() {
		this.markComplete()
		this.updateTitle()
		this.showEditButton()
		this.hideStep()
		this.showNextStep()
	}

	updateTitle() {
		if (this.isStepComplete()) {
			const userName = this.userIdInputTarget.dataset.userName
			const new_title = `<span class='d-none d-sm-inline'>User<i class='fa fal fa-angle-right mx-1'></i></span><span class='text-success'>${userName}</span>`
			this.replaceTitle(new_title)
		} else {
			this.replaceTitle('Select Employee')
		}
	}

	showNextStep() {
		Promise.resolve().then( () => {
			document.querySelector('#absence-form-date-step').dispatchEvent(new CustomEvent('start-step'))
		})
	}

}
