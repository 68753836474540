import $ from 'jquery'

addEventListener('turbo:submit-start', ({ target } ) => {
	for (const element of target.querySelectorAll('.disable-on-submit')) {
		element.classList.add('disabled')
	}
})

addEventListener('turbo:submit-end', ({ target } ) => {
	for (const modal of target.querySelectorAll('.modal.show:not(.ignore-disable-on-submit)')) {
		$(modal).modal('hide')
	}

	for (const element of target.querySelectorAll('.disable-on-submit')) {
		setTimeout( () => {
			if (element) { element.classList.remove('disabled') }
		}, 250)
	}

})
