import ApplicationController from './application_controller'
import 'bootstrap/js/dist/collapse'

export default class extends ApplicationController {
	static targets = [
		'progressIcon',
		'title',
		'editButton',
		'content',
		'continueButton',
	]

	connect() {
		this.contentCollapse = new bootstrap.Collapse(this.contentTarget, { toggle: false })
	}

	showEditButton() {
		this.editButtonTarget.classList.remove('d-none')
	}

	hideEditButton() {
		this.editButtonTarget.classList.add('d-none')
	}

	markComplete() {
		this.progressIconTarget.classList.add('fa-circle-check')
		this.progressIconTarget.classList.add('text-success')
		this.progressIconTarget.classList.remove('fa-circle-dashed')
		this.progressIconTarget.classList.remove('text-secondary')
	}

	markIncomplete() {
		this.progressIconTarget.classList.add('fa-circle-dashed')
		this.progressIconTarget.classList.add('text-secondary')
		this.progressIconTarget.classList.remove('fa-circle-check')
		this.progressIconTarget.classList.remove('text-success')
	}

	showStep() {
		this.contentCollapse.show()
	}

	replaceTitle(new_title) {
		this.titleTarget.innerHTML = new_title
	}

	enableContinueButton() {
		this.continueButtonTarget.classList.remove('disabled')
	}

	disableContinueButton() {
		this.continueButtonTarget.classList.add('disabled')
	}

	editStep() {
		this.editButtonTarget.innerHTML = '<div class="fa fal fa-angle-up"></div>Hide'
		this.editButtonTarget.dataset.action = this.identifier + '#hideStep'
		this.showStep()
	}

	hideStep() {
		this.editButtonTarget.innerHTML = '<div class="fa fal fa-pencil-alt"></div>Edit'
		this.editButtonTarget.dataset.action = this.identifier + '#editStep'
		this.contentCollapse.hide()
	}
}
