import ApplicationController from './application_controller'
import flatpicker from 'flatpickr'
//import dayjs from 'dayjs'

export default class extends ApplicationController {

	static values = {
		'mode':    String,
		'minDate': String,
		'inline':  String,
		'submitOnValueUpdate':  String,
	}

	connect() {
		this.initFlatpickr()
	}

	initFlatpickr() {
		const inline  = this.inlineValue  || 'false'
		const mode    = this.modeValue    || 'single'
		const minDate = this.minDateValue || 'today'

		const flatpickr_config = {
			dateFormat: 'Y-m-d',
			mode: mode,
			inline: inline,
			minDate: minDate,
			altInput: true,
			altFormat: "F j, Y",
			appendTo: this.element.closest('.calendar-container'),
			position: 'below center',
			onValueUpdate: this.onValueUpdate
		}

		this.calendar = flatpickr(this.element, flatpickr_config)

		this.calendar.config.onClose.push(this.resetFlatpickrButtons)

		if (this.submitOnValueUpdateValue === 'true') {
			this.calendar.config.onChange.push(this.submitOnValueUpdate)
		}
	}

	resetFlatpickrButtons() {
		for (const flatpickrButton of document.querySelectorAll("div[data-controller='flatpickr-button']")) {
			flatpickrButton.dispatchEvent(new CustomEvent('set-button-to-show'))
		}
	}

	submitOnValueUpdate(selectedDates, dateStr, instance) {
		if (dateStr && /^\d\d\d\d-\d\d-\d\d to \d\d\d\d-\d\d-\d\d$/.test(dateStr)) {
			instance.input.closest('form').submit()
		}
	}

	open() {
		this.calendar.open()
	}

	close() {
		this.calendar.close()
	}
}

