import ApplicationController from './application_controller'

export default class extends ApplicationController {

	static values = {
		'numberOfDays': Number,
	}

	static targets = [
		'day',
		'dayName',
		'event',
		'timelineHeader',
	]

	connect() {
		this.currentWidth = this.readTimelineWidthCookie()
		this.initTimelineZoom()
		this.redrawTimeline()
	}

	initTimelineZoom() {
		document.querySelector('#scale-timeline').value = this.currentWidth
	}

	redrawTimeline() {
		this.setDayWidths()
		this.setTimelineHeaderWidth()
		this.setEventLabelWidths()
		if (this.currentWidth <= 90) { this.hideTinyClasses() }

		if (this.currentWidth >= 91) { this.showTinyClasses() }
	}

	setDayWidths() {
		for (const dayElement of this.dayTargets) {
			dayElement.style.width = `${this.currentWidth}px`;
		}
	}

	setEventLabelWidths() {
		for (const eventElement of this.eventTargets) {
			const oneUnitWidth = this.currentWidth
			const totalUnits = eventElement.dataset.duration
			const totalWidth = oneUnitWidth * totalUnits
			const eventLabelElement = eventElement.querySelector('.event-label')
			eventLabelElement.style.width = `${totalWidth - 9}px`;
		}
	}

	setTimelineHeaderWidth() {
		document.querySelector('#timeline-header').style.width = `${this.element.offsetWidth}px`
	}

	showUserNameFirstInitials() {
		for (const dayNameElement of this.dayNameTargets) {
			dayNameElement.classList.remove('d-none')
		}
	}

	showTinyClasses() {
		for (const element of this.element.querySelectorAll('.hidden-when-tiny')) {
			element.classList.remove('d-none')
		}
	}

	hideTinyClasses() {
		for (const element of this.element.querySelectorAll('.hidden-when-tiny')) {
			element.classList.add('d-none')
		}
	}

	resizeTimeline(event) {
		this.updateWidth(event.detail.newSize)
	}

	fitTimeperiodToScreen() {
		const widthPerDay = Math.floor(document.querySelector('.timeline-container').clientWidth / this.numberOfDaysValue)
		this.updateWidth(widthPerDay)
		document.querySelector("input[name='timeline_scale']").value = widthPerDay
	}

	fit2WeeksToScreen() {
		const widthPerDay = Math.floor(document.querySelector('.timeline-container').clientWidth / 14)
		this.updateWidth(widthPerDay)
		document.querySelector("input[name='timeline_scale']").value = widthPerDay
	}

	updateWidth(newWidth) {
		this.updateTimelineWidthCookie(newWidth)
		this.currentWidth = newWidth
		this.redrawTimeline()
	}

	updateTimelineWidthCookie(newWidth) {
		document.cookie = `absenceTimelineWidth=${newWidth}; SameSite=Lax; Secure`
	}

	readTimelineWidthCookie() {
		const timelineWidth = document.cookie.split('; ').find((row) => row.startsWith('absenceTimelineWidth='))?.split('=')[1];
		if (timelineWidth) {
			return new Number(timelineWidth)
		} else {
			return 84
		}
	}
}
