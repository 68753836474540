addEventListener('turbo:load', () => {
	for (let element of document.querySelectorAll('[data-bs-toggle="tooltip"]')) {
		let tooltip = bootstrap.Tooltip.getOrCreateInstance(element)
		tooltip.enable()
	}
})

addEventListener('turbo:before-cache', () => {
	for (let element of document.querySelectorAll('[data-bs-toggle="tooltip"]')) {
		let tooltip = bootstrap.Tooltip.getOrCreateInstance(element)
		tooltip.dispose()
	}
})
