import ReflexApplicationController from '../reflex_application_controller'

/* This is the custom StimulusReflex controller for the PaystubImports Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ReflexApplicationController {
	/*
	 * Regular Stimulus lifecycle methods
	 * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
	 *
	 * If you intend to use this controller as a regular stimulus controller as well,
	 * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
	 *
	 * Important:
	 * By default, StimulusReflex overrides the -connect- method so make sure you
	 * call super if you intend to do anything else when this controller connects.
	 */

	connect () {
		super.connect()
		// add your code here, if applicable
	}

	addEmployeeIdToUser (element, reflex, error, reflexId) {
		$('#search-existing-users-modal').modal('hide')
		this.stimulate('W2Imports#add_employee_id_to_user')
	}

	/* Reflex specific lifecycle methods.
	 *
	 * For every method defined in your Reflex class, a matching set of lifecycle methods become available
	 * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
	 * need them.
	 *
	 * Important:
	 * Make sure to add data-controller="paystub-imports" to your markup alongside
	 * data-reflex="PaystubImports#dance" for the lifecycle methods to fire properly.
	 *
	 * Example:
	 *
	 *   <a href="#" data-reflex="click->PaystubImports#dance" data-controller="paystub-imports">Dance!</a>
	 *
	 * Arguments:
	 *
	 *   element - the element that triggered the reflex
	 *             may be different than the Stimulus controller's this.element
	 *
	 *   reflex - the name of the reflex e.g. "PaystubImports#dance"
	 *
	 *   error/noop - the error message (for reflexError), otherwise null
	 *
	 *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
	 */

	// Assuming you create a "PaystubImports#dance" action in your Reflex class
	// you'll be able to use the following lifecycle methods:

	// beforeDance(element, reflex, noop, reflexId) {
	//  element.innerText = 'Putting dance shoes on...'
	// }

	// danceSuccess(element, reflex, noop, reflexId) {
	//   element.innerText = '\nDanced like no one was watching! Was someone watching?'
	// }

	// danceError(element, reflex, error, reflexId) {
	//   console.error('danceError', error);
	//   element.innerText = "\nCouldn\'t dance!"
	// }

	// afterDance(element, reflex, noop, reflexId) {
	//   element.innerText = '\nWhatever that was, it\'s over now.'
	// }

	// finalizeDance(element, reflex, noop, reflexId) {
	//   element.innerText = '\nNow, the cleanup can begin!'
	// }

}
