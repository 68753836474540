import FormStepController from '../form_step_controller'

export default class extends FormStepController {

	static targets = [
		'ptoTypeList',
		'ptoTypeItem',
		'amountSpecifiedLabel',
		'progressIcon',
		'title',
		'editButton',
		'continueButton',
		'content',
	]

	connect() {
		super.connect()
		this.updateUI()
		if (this.isStepComplete()) {
			this.markComplete()
			this.completeStep()
		}
	}

	updatePTOType(event) {
		this.updateUI()

		if (event.currentTarget.checked) {
			this.selectPTOTypeElement(event.currentTarget.closest('.input-group'))
		} else {
			this.unselectPTOTypeElement(event.currentTarget.closest('.input-group'))
		}

		if (this.isStepComplete()) {
			this.enableContinueButton()
			this.markComplete()
		} else {
			this.disableContinueButton()
			this.markIncomplete()
		}
	}

	selectPTOTypeElement(element) {
		if (element) {
			element.querySelector('input.absence-form-pto-type-amount').disabled = false
		}
	}

	unselectPTOTypeElement(element) {
		if (element) {
			const amountElement = element.querySelector('input.absence-form-pto-type-amount')
			amountElement.disabled = true
			amountElement.value = ''
			const imaskController = this.application.getControllerForElementAndIdentifier(amountElement, 'imask')
			imaskController.reset_value()
		}
	}

	updateAmount(event) {
		this.updateUI()
		if (this.isStepComplete()) {
			this.enableContinueButton()
			this.markComplete()
		} else {
			this.disableContinueButton()
			this.markIncomplete()
		}
	}

	getTotalAmount() {
		let total = 0
		for (const ptoItem of this.ptoTypeItemTargets) {
			const checkbox = ptoItem.querySelector('input.absence-form-pto-type-active')
			if (checkbox.checked) {
				const amount = ptoItem.querySelector('input.absence-form-pto-type-amount').value
				if (amount) {
					total += Number(amount)
				}
			}
		}
		return total
	}

	getTotalAmountForDisplay() {
		const totalAmount = this.getTotalAmount()
		if (Number.isInteger(totalAmount)) {
			return totalAmount
		} else {
			return totalAmount.toFixed(2)
		}
	}

	updateTotalAmount() {
		this.amountSpecifiedLabelTarget.innerHTML = `${this.getTotalAmountForDisplay()} Hours Total`
	}

	updateTitle() {
		if (this.isStepComplete()) {
			const new_title = `<span class='d-none d-sm-inline'>Time Off<i class='fa fal fa-angle-right mx-1'></i></span><span class='text-success'>${this.getTotalAmountForDisplay()} Hours</span>`
			this.replaceTitle(new_title)
		} else {
			const new_title = 'Specify Time Off'
			this.replaceTitle(new_title)
		}
	}

	updateSubmitStep() {
		const typeAmountTuples = this.getCheckedTypeAmountTuples()
		const ptoTypeElements = typeAmountTuples.map(tuple => `${tuple[1]} Hours of ${tuple[0]} Leave`)

		let new_time_off_content = ptoTypeElements.join('<br>')

		if (typeAmountTuples.length > 1) {
			new_time_off_content += `<hr class='my-1'><b>${this.getTotalAmountForDisplay()} Total Hours</b>`
		}

		const selectedPtoTypesElement = document.querySelector('#absence-form-submit-step td.selected-pto-types')
		selectedPtoTypesElement.innerHTML = new_time_off_content
	}
	
	getCheckedTypeAmountTuples() {
		const typeAmountTuples = []
		for (const ptoTypeItem of this.ptoTypeItemTargets) {
			const checkbox = ptoTypeItem.querySelector('input.absence-form-pto-type-active')
			if (checkbox.checked) {
				const ptoTypeName = ptoTypeItem.dataset.ptoTypeName
				const amountInputValue = ptoTypeItem.querySelector('input.absence-form-pto-type-amount').value
				typeAmountTuples.push([ptoTypeName, amountInputValue])
			}
		}
		return typeAmountTuples
	}

	updateUI() {
		this.updateTotalAmount()
		this.updateTitle()
		this.updateSubmitStep()
	}

	isStepComplete() {
		if(!this.hasPtoTypeItemTarget) return false 

		let oneChecked = false
		for (const ptoItem of this.ptoTypeItemTargets) {
			const checkbox = ptoItem.querySelector('input.absence-form-pto-type-active')
			if (checkbox.checked) {
				oneChecked = true
				const amount = ptoItem.querySelector('input.absence-form-pto-type-amount').value
				if (!amount) return false
				const floatAmount = parseFloat(amount)
				if (floatAmount === NaN || floatAmount <= 0.0) return false
			}
		}
		//if (this.getTotalAmount() > 0.0) { return true }
		if (oneChecked) return true
		return false
	}

	completeStep() {
		this.showEditButton()
		this.hideStep()
		this.showNextStep()
	}

	startStep() {
		if (this.isStepComplete()) {
			this.showNextStep()
		} else {
			this.showStep()
		}
	}

	showNextStep() {
		Promise.resolve().then( () => {
			document.querySelector('#absence-form-submit-step').dispatchEvent(new CustomEvent('start-step'))
		})
	}

}
